import { BaseModel } from './base-model';
import { Company } from './company';

export enum UserRole {
  admin = 'admin',
  employee = 'employee',
  superAdmin = 'superAdmin',
}

export const UserRoleTranslations: Record<UserRole, string> = {
  [UserRole.admin]: 'Admin',
  [UserRole.employee]: 'Employee',
  [UserRole.superAdmin]: 'Super Admin',
};

export const UserRoleAll = Object.values(UserRole);

export enum UserStatus {
  enabled = 'enabled',
  disabled = 'disabled',
  invited = 'invited',
}

interface BaseUser extends BaseModel {
  email: string;
  status: UserStatus;
}

export interface InviteUserData {
  email: string;
  fullName: string;
  role: UserRole;
}

export interface UpdateUserData {
  fullName: string;
  currentPassword?: string;
  newPassword?: string;
}

export interface User extends BaseUser {
  fullName: string;
  profilePicture?: string;
  profilePictureUrl?: string;
  role: UserRole;
  companyId: string;
  company?: Company;
}
